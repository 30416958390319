.createPayments {
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__bpWidget {
    margin-right: 28px;
  }
}
