.toasterDisplayContainer {
  position: absolute;
  top: 70px;
  right: 0;
  @media (max-width: 600px) {
    width: 100%;
    top: 50px;

    & .toastDisplay {
      width: 100%;
    }
  }
}

.toastDisplay {
  border-radius: 4px;

  &.error {
    color: #ff4d64;
    background: #fdf2f2;
  }

  &.success {
    background: #ccffe1;
    color: #1a9e40;
  }

  &.info {
    color: #f6fdfe;
    background-color: #153243;
  }

  .toast-text {
    font-family: Heebo;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.005em;
    text-align: left;
    display: flex;
    align-items: center;

    .close-button {
      cursor: pointer;
    }
  }
}

.bold {
  font-weight: bold;
}
