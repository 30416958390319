.readButton {
  color: blue;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.005px;
  width: 100%;
  text-align: center;
  @media (min-width: 600px) {
    width: 150px;
    float: right;
    margin-right: 8px;
  }
}
.notificationTitle {
  font-size: 24px;
  font-weight: 500;
  color: #153243;
  font-family: 'Heebo';
  line-height: 21px;
}
.notificationSubtitle {
  color: #73848e;
  font-size: 14px;
  line-height: 21px;
  font-family: 'Heebo';
  line-height: 21px;
  margin: 32px 0;
  @media (max-width: 600px) {
    margin-bottom: 15px;
  }
}
