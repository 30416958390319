$default-color: #153243;
$active-color: #055aee;
$disabled-color: #8a99a1;
$hr-color: #b9c2c7;

.lynk-modal {
  @media (min-width: 600px) {
    min-width: 560px;
  }
  @media only screen and (max-width: 500px) {
    /* For mobile phones: */
    width: 90%;
    margin-left: 5%;
  }

  & &__title {
    padding: 0;
    margin-top: 14px;
    // padding-bottom: 34px;
    width: 100%;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 500px) {
      /* For mobile phones: */
      padding-bottom: 25px;
    }

    & .titleDesc {
      margin-bottom: 25px;
      font-size: 32px;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 0.0015em;
      color: $active-color;
    }
  }

  .modal-content {
    padding: 34px 24px 24px 24px !important;
    @media only screen and (max-width: 500px) {
      /* For mobile phones: */
      padding: 36px 16px;
    }
  }

  .modal-body {
    word-wrap: break-word;
    @media only screen and (max-width: 500px) {
      /* For mobile phones: */
      padding-top: 0px !important;
    }
  }
}

.lynk-modal .modal-header:not(.customModal) {
  border-bottom: none !important;
}

.lynk-modal.customModal .lynk-modal__title {
  border-bottom: none !important;
  text-align: center;
  &.showNotClose {
    justify-content: center !important;
  }
  &.showClose {
    justify-content: center !important;
    padding-top: 15px;
  }
  & .titleDesc {
    flex-direction: column;
    align-items: center;
    color: #055aee;
    font-size: 32px;
    margin-bottom: 0px;
  }

  & .subtitleDesc {
    margin-top: 24px;
    font-size: 16px !important;
    // display: none;
  }
}

.headerContainer.customModal {
  display: flex;
  flex-direction: column-reverse;
}

.lynk-modal .modal-body {
  min-height: fit-content !important;
}

.modal-footer {
  display: block;
  padding-top: 16px;
  border-top: none;
  @media only screen and (max-width: 600px) {
    /* For mobile phones: */
    padding: 0px;
  }
}

.lynk-modal .modal-footer:not(.customModal) {
  border-top: none !important;
}

.loading-overlay {
  height: 100%;
  z-index: 20px;
  position: absolute;
  width: 100%;
  left: 5px;
  top: 5px;
  background: gray;
  z-index: 20;
  opacity: 0.3;
  padding: 20px;
  pointer-events: none;
}

.btn-cancel {
  border: none;
  color: $default-color;
  background-color: transparent;
  &:hover {
    color: $active-color;
    background-color: transparent;
  }
}

.btn-apply {
  background-color: $active-color;
  &:hover {
    background-color: $default-color;
  }
  &.disabled,
  &.disabled:hover {
    background-color: $disabled-color;
    cursor: not-allowed;
  }
}
.secondaryAction {
  text-align: center;
  color: #455b69;
  cursor: pointer;
  margin: 20px 5px 0px 5px;
  padding: 10px 10px 0px 10px;
  font-size: 16px;
}

@media (max-width: 600px) {
  .subtitleDesc {
    font-size: 15px;
  }
  .notDisplayMobile {
    display: none;
  }
}
