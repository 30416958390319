.miniFileUploadContainer {
  cursor: pointer;
  position: relative;
  border: 1px solid #cecece;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  align-items: center;

  .fileLabel {
    cursor: pointer;
  }

  &.is-invalid {
    border: 1px solid red;
  }

  .uploadContainer {
    position: absolute;
    right: 5px;
    background: white;
    height: 100%;
    display: flex;
    align-items: center;
    &:not(.error) {
      right: 14px;
    }
    .uploadIcon {
      width: 9px;
      &.error {
        color: red;
        width: 32px;
      }
    }
  }
}

.miniFileUpload__input {
  cursor: pointer;
}

@media (max-width: 600px) {
  .text-input__feedback > div {
    font-size: 12px !important;
  }
}
