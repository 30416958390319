.filterListingItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 12px;
  gap: 12px;
  width: 280px;
  height: 45px;
  background: #0559ee;
  border-radius: 4px;
  color: white;

  @media (max-width: 600px) {
    & {
      width: 100%;
      justify-content: space-between;
    }
  }
}

.closeIcon {
  cursor: pointer;
}
