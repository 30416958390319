.notifications {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  position: relative;

  @media (max-width: 600px) {
    padding: 0;
  }

  & &__indicator {
    width: 21px;
    height: 21px;
    padding: 3px 4px;

    background-color: #ff4d64;
    color: #fff;
    border-radius: 50%;
    border: 1px solid white;

    font-size: 8px;
    font-family: 'Heebo';

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    left: 50%;
    bottom: 50%;
  }

  & &__btn {
    & button {
      background: none;
      outline: none;
      cursor: pointer;
    }
  }

  & &__backdrop {
    width: 400vh;
    height: 100vh;

    position: absolute;
    top: 70px;
    bottom: 0;

    background: rgba(0, 0, 0, 0.2);

    pointer-events: none;
    z-index: 20;
  }

  & &__menu {
    padding: 36px;
    width: 500px;

    border-radius: 0px 0px 8px 8px;
    background-color: #f4f8f9;

    @media (max-width: 600px) {
      position: absolute;
      right: 0;
      left: auto;
      margin: auto;
      width: calc(100vw - -115px);
      padding: 16px;
    }

    @media (max-width: 374px) {
      padding: 16px 16px 16px 12px;
    }

    @media (max-width: 420px) {
      padding: 16px 16px 16px 13px;
    }

    @media (max-height: 773px) {
      overflow: scroll;
      height: 90vh;
    }
  }

  & &__title {
    color: #055aee;

    font-family: 'Armin Grotesk';
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.64px;

    margin: 0;

    @media (max-width: 420px) {
      width: 180px;
    }
  }

  & &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 600px) {
      width: calc(100vw - 5%);
    }
  }

  & &__settings {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: #153243;
  }

  & &__view {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 11px 12px;
    margin-top: 28px;
    border-radius: 4px;
    border: 1px solid #055aee;

    text-decoration: none;

    color: #055aee;
    font-family: 'Heebo';
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.08px;

    @media (max-width: 600px) {
      width: calc(100vw - 5%);
    }
  }

  & &__dropdown {
    &--item {
      margin: 12px 0;
      padding: 12px;
      border-radius: 8px;

      background-color: #fff;

      overflow: hidden;
      color: #73848e;
      text-overflow: ellipsis;
      font-family: 'Heebo';
      font-size: 15.5px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.08px;
      cursor: pointer;

      @media (max-width: 600px) {
        width: calc(100vw - 5%);
      }
    }

    &--unread {
      margin: 12px 0;
      padding: 12px;
      border-radius: 8px;

      background-color: #e6effd;

      color: #73848e;
      font-family: 'Heebo';
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.08px;
      cursor: pointer;

      @media (max-width: 600px) {
        width: calc(100vw - 5%);
      }
    }

    &--headline-title {
      color: #153243;

      font-size: 15.5px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0.08px;

      width: 280px;
      text-overflow: ellipsis;
      overflow: hidden;
      text-wrap: nowrap;

      @media (max-width: 600px) {
        width: 218px;
      }

      @media (max-width: 374px) {
        width: 165px;
      }
    }

    &--inner {
      padding: 5px 0 5px 12px;
      font-size: 15.5px;
      &.active {
        border-left: 3px solid #0559ee;
      }
    }

    &--headline {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      margin-bottom: 8px;
    }

    &--headline-time {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.07px;
    }

    &--notif-body {
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 15.5px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.btn-secondary {
  --bs-btn-bg: none;
  --bs-btn-border-color: none;
  --bs-btn-hover-color: none;
  --bs-btn-hover-bg: none;
}

.dropdown-menu {
  padding: 0;

  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  margin-top: 0.3rem;
  position: absolute;
  z-index: 1000;
  &.show {
    top: 100% !important;
    left: unset !important;
    right: 0 !important;
  }
}

.notifications__backdrop {
  width: 400vw !important;
}

@keyframes DropDownSlide {
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
