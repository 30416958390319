.balanceValueContainer {
  width: 100%;
  display: inline-flex;
}

.currencySymbol {
  color: white;
  font-size: 15px;
  padding-right: 5px;
  align-items: baseline;
  margin-bottom: -4px;
}

.showIcon {
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.balanceValueContent {
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-family: Armingrotesk;
}

.amountDisplayContainer {
  font-family: Armingrotesk;
  font-size: 32px;

  &.skeleton {
    position: relative;
    width: 100%;
  }

  @media (max-width: 768px) {
    font-size: 25px;
  }
}

.eyeToggle {
  & &__visible,
  & &__hidden {
    font-size: 30px !important;
  }
}
