.exportPreferencesSection {
  position: relative;

  & &--disabled {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-color: white;
  }
  & &-subTitle {
    margin-top: -40px;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.005em;
    color: #73848e;
  }

  & .sectionView-body {
    margin-left: 12px;

    &Text {
      color: #73848e;
      margin-bottom: 23.5px;
    }
  }
}

.checkboxList-title {
  &.invalid {
    color: red;

    & span {
      color: gray;
      font-style: italic;
    }
  }
  column-gap: 12px;
  display: flex;
}

.sectionView-body {
  &.invalid {
    & .checkgroup-item * {
      color: red;
    }
  }
}

.checkboxList-message--invalid {
  font-size: 12px;
}
