$active-color: #055aee;
$default-color: #153243;
$disabled-color: #8a99a1;

$font-family-Heebo: Heebo, sans-sarif;

.form-type-refund {
  & &__type {
    width: 100%;

    &__radio-button {
      width: 100%;
      margin-left: 0;
      border: solid 1px;
      border-radius: 4px;
      padding: 24px 15px;

      label {
        margin-bottom: 0;
      }

      .label {
        display: flex;
        flex-direction: column;

        .title {
          font-family: $font-family-Heebo;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
          color: $default-color;
        }

        .description {
          font-family: $font-family-Heebo;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #5b707b;
        }
      }
    }

    .selected {
      border-color: $active-color;
    }

    &__feedback {
      font-family: $font-family-Heebo;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      letter-spacing: 0.004em;
      color: $disabled-color;
      margin-bottom: 24px;
      margin-top: 8px;
    }
  }

  & &__actions {
    padding-top: 16px;
    margin-top: 32px;

    &__cancel-button {
      border: 1px solid #8a99a1;
      margin-top: 16px;
      font-family: $font-family-Heebo;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.005em;
      color: #153243;
    }

    &__primary-button {
      font-family: $font-family-Heebo;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.005em;
      color: #ffffff;
    }
  }
}
