.loader {
  background: transparent;
  display: flex;
  justify-content: center;
  column-gap: 4px;
  align-items: center;
}

.status {
  width: 25px;
  height: 25px;
  & svg {
    transition: all 2s ease-out;
    animation-name: spin;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: running;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
