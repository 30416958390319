.qrIconContainer {
  .qrIconAtom {
    width: 100%;
    height: 100%;

    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;

    &.default {
      transform: rotate(317deg);
    }

    &.skeleton {
      border-radius: 0px !important;
      transform: rotate(45deg);
    }
  }

  .qrIcon > canvas {
    margin-top: -70px !important;
    margin-left: -50px !important;
    width: 230px;
    height: 230px;
    position: relative;
  }
}
