.card__sectionContainer {
  &--empty {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
  }
}

tr.emptyMobileDisplayContainer {
  height: 240px;
  background: white;

  & td.table__empty-message {
    width: 100%;
    text-align: center;
    height: 240px;
  }
}

.noDisplay {
  display: none !important;
}
