.tableCardContainer {
  background: #ffffff;
  box-shadow: 0px 1.8px 3.6px rgba(15, 34, 58, 0.125);
  border-radius: 16px;
  padding: 12px;

  .title {
    background: #e8ebec;
    border-radius: 24px;
    padding: 2px 8px;
    // padding: 6px;
    font-size: 12px;
    color: #455b69;
    font-family: 'Heebo';
    // border-radius: 40%;
  }

  .transAmt {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.005em;

    /* darkBlue/Dark100 */

    color: #153243;
  }

  .username {
    font-family: 'Armin Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: 0.001em;
    color: #2c4756;
    word-wrap: break-word;
    overflow-wrap: anywhere;
  }

  .transTimestamp {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    text-align: left;
    letter-spacing: 0.004em;

    /* darkBlue/Dark400 */

    color: #5b707b;
  }

  .moreDetailsIcon {
    color: #5b707b;
    cursor: pointer;
  }

  .cardTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  .cardDataContainer {
    display: flex;
    justify-content: space-between;
  }
}
