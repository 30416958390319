.detailRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;

  & div.downloadLink {
    color: #0559ee !important;
    cursor: pointer;
    & svg {
      width: 15px;
    }
  }

  & div:not(.downloadLink) {
    color: #153243;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 600;
    font-size: 14.4px;
    line-height: 21px;
    letter-spacing: 0.005em;
    color: #153243;
  }
  @media (max-width: 600px) {
    & {
      display: inline;
    }
    & div {
      margin-top: 10px;
      font-weight: 400 !important;
      color: #153243 !important;
    }
    & .divRow {
      color: #5b707b !important;

      font-size: 12px !important;
    }
  }
}
.estimatedFields {
  margin-top: 20px;
  font-size: 12px;
  @media (max-width: 600px) {
    & {
      font-size: 14px;
      color: #2c4756;
    }
  }
}

.exceedLimitContainer {
  background-color: #fdf2f2;
  color: #ff4d64;

  border-radius: 4px;
  padding: 12px;
  margin-top: 15px;

  display: flex;
  flex-direction: row;

  @media (max-width: 600px) {
    & {
      align-items: start;
    }
  }
}

.exceedLimitContainer-icon {
  display: flex;
  margin-right: 10px;
}

.exceedLimitContainer-text {
  margin: 0;
  font-size: 14px;
}

.disabled {
  pointer-events: none;
}

.payeeNameTooltip {
  width: 138px;
  padding: 8px 12px;
  border-radius: 8px;
  background: #153243;
  color: white !important;

  position: absolute;
  top: -65px;
}

.payeeNameContainer {
  position: relative;
}
