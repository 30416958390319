.transactionModal--desktop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px !important;
}

.modalBody--desktop {
  width: 100% !important;
  padding: 1rem 1rem;
}
