.detailsContainer {
  display: flex;
  align-items: center;
  color: #73848e;
  cursor: pointer;

  & &__text {
    font-family: Heebo;
    font-size: 14px;
    font-weight: 400;
    color: #153243;
  }

  & &__icon {
    margin-right: 0px;
    height: 15px;
    width: 15px;
    margin-left: 4px;
  }

  .btnText {
    color: #5b707b;
  }
}

.tooltip.buttonTooltip {
  opacity: 1 !important;
  min-width: 500px !important;
  max-width: 100% !important;
  padding: 0;
  margin: 0;
  border: none;
  @media (max-width: 600px) {
    & {
      min-width: 220px !important;
      margin-left: 30px !important;
    }
  }
  .detailsContainer__tooltip {
    width: 100% !important;
    text-align: left;
    // font-family: Heebo;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.004em;
    border-radius: 8px !important;
    background-color: #153243;

    &-content {
      text-align: left;
      // font-family: Heebo;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.004em;
      border-radius: 0 !important;
      width: 100%;
      max-width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      justify-content: flex-start;

      display: flex !important;
      align-items: center;
      background-color: #153243;
      @media (max-width: 600px) {
        & {
          padding: 5px;
        }
      }
      &Icon {
        margin-right: 5px;
      }
    }

    .tooltip-arrow {
      display: none !important;
    }
  }
}

.batch-payments-stat {
  width: 150px;
  @media (max-width: 600px) {
    & {
      width: auto !important;
    }
  }
}
