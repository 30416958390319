.downloadTemplateBatch {
  .detailsContainer__icon {
    height: 20px;
    margin-left: 12px;
  }
}
@media screen and (max-width: 600px) {
  .noMobile {
    display: none;
  }
}
