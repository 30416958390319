.qrCodeDownloadContainer {
  margin: 0 auto;
  width: 515px;
  padding-top: 60px;
  height: 700px;
  background-image: url(../../../assets/images/scan_to_pay.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  position: relative;

  header {
    margin-bottom: 45px;
    & > div {
      position: relative;
      width: 100%;

      .lynkLogo {
        border: 1px solid #05af05;
        padding: 24px;
        border-radius: 100%;
        width: 130px;
        height: 130px;
        background-color: white;
        z-index: 3;
        top: -20px;
        left: 20px;
        position: absolute;
        & svg {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .qrSection {
    padding-top: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(45deg);
    & > div {
      border: 1px solid #05af05;
      background: #fff;
      height: 250px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: 250px;
      border-radius: 20px;
      position: relative;

      & > img {
        margin: 0;
        transform: rotate(-45deg);
        width: 320px;
        height: 320px;
        object-fit: fill;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
      }
    }
  }

  .merchantDetailGroup {
    line-height: 1.5em;
    background-color: #041eb8;
    height: 90px;
    width: 100%;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 0px 10px 0px 170px;
    display: flex;
    align-items: center;
    color: white;

    .merchantTitle {
      align-items: flex-start !important;
    }
    & .merchantTitle__name {
      font-family: 'Armin Grotesk';
      font-size: 88px;
      font-weight: 800;
      // line-height: 125px;
      letter-spacing: 0.04em;
      text-align: left;
      font-size: 22px;
    }
    & .merchantTitle__cashier {
      font-size: 18px;
      font-family: 'Armin Grotesk';
      font-weight: 300;
      letter-spacing: 0.04em;
      text-align: left;
    }
    & .merchantTitle__id {
      font-size: 15px;
      font-family: 'Armin Grotesk';
      font-weight: 250;
      letter-spacing: 0.04em;
      text-align: left;
      color: white;
    }
  }

  .details {
    color: white;
    display: flex;
    justify-content: space-between;
  }

  .footerDetails {
    margin-top: 58px;
    padding: 0px 55px;
    padding-top: 30px;
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 48px;

    & .scanToPay {
      width: 220px;
    }

    & .lynkLogo {
      width: 223px;
      margin-top: 3px;
    }

    .downloadGrp {
      color: white;
      width: 145px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 20px;
      & .storeOptions {
        margin-bottom: 3px;
        display: flex;
        align-items: center;

        .appleIcon {
          font-size: 30px;
          margin-right: 9px;
        }

        .playStore {
          width: 33px;
          border-left: 1px solid;
          padding-left: 12px;
        }
      }

      & .downloadNow {
        color: white;
        font-size: 15px;
        font-family: 'Armin Grotesk';
        font-weight: 400;
        letter-spacing: 0.04em;
        text-align: left;
      }
    }

    & .lynkLogo {
      width: 250px;
      height: 101px;
    }
  }
}
