//
// timeline.scss
//

/************** Horizontal timeline **************/

.hori-timeline {
  .events {
    .event-list {
      text-align: center;
      display: block;

      &::after {
        content: '\e858';
        font-family: 'unicons-line';
        display: block;
        position: absolute;
        top: 34px;
        left: 0;
        right: 0;
        margin: 0px auto;
        color: $gray-400;
        font-size: 24px;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 14px;
        left: 0;
        right: 0;
        width: 100%;
        height: 2px;
        border-radius: 50%;
        background-color: $gray-300;
      }

      .event-date {
        display: inline-block;
        position: relative;
        border-radius: 4px;
        background-color: rgba($primary, 0.2);
        padding: 2px 12px;
        margin-bottom: 48px;
      }
    }
  }
}

/************** vertical timeline **************/

.verti-timeline {
  padding-left: 100px;
  .event-list {
    border-left: 3px dashed $gray-300;
    position: relative;
    padding: 0px 0px 30px 30px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 6px;
      left: -6px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 2px solid $primary;
      background-color: $card-bg;
    }

    .event-date {
      display: inline-block;
      border-radius: 4px;
      background-color: rgba($primary, 0.2);
      color: $primary;
      padding: 2px 12px;
      position: absolute;
      left: -102px;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}
