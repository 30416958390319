.startText,
.endText {
  &:focus {
    border: blue;
  }
}

.doubleInputContainer {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  column-gap: 12px;
}

@media (max-width: 600px) {
  .doubleInputContainer {
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    column-gap: 0px;
  }
}

.dateGroup {
  width: 100%;
  
}
