/*
Template Name: Minible - Admin & Dashboard Template
Author: Themesbrand
Version: 2.3.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import 'custom/fonts/fonts';
@import 'react-loading-skeleton/dist/skeleton.css';

//Core files
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import 'variables';
@import './node_modules/bootstrap/scss/mixins.scss';

// Structure
@import 'custom/structure/general';
@import 'custom/structure/topbar';
@import 'custom/structure/page-head';
@import 'custom/structure/footer';
@import 'custom/structure/right-sidebar';
@import 'custom/structure/vertical';
@import 'custom/structure/horizontal-nav';
@import 'custom/structure/layouts';

// Components
@import 'custom/components/waves';
@import 'custom/components/avatar';
@import 'custom/components/alerts';
@import 'custom/components/accordion';
@import 'custom/components/helper';
@import 'custom/components/preloader';
@import 'custom/components/forms';
@import 'custom/components/widgets';
@import 'custom/components/demos';
@import 'custom/components/print';

// Plugins
@import 'custom/plugins/custom-scrollbar';
@import 'custom/plugins/calendar';
@import 'custom/plugins/session-timeout';
@import 'custom/plugins/range-slider';
@import 'custom/plugins/sweatalert2';
@import 'custom/plugins/rating';
@import 'custom/plugins/toastr';
@import 'custom/plugins/parsley';
@import 'custom/plugins/select2';
@import 'custom/plugins/switch';
@import 'custom/plugins/colorpicker';
@import 'custom/plugins/datepicker';
@import 'custom/plugins/bootstrap-touchspin';
@import 'custom/plugins/repeater';
@import 'custom/plugins/form-editors';
@import 'custom/plugins/xeditable';
@import 'custom/plugins/form-upload';
@import 'custom/plugins/form-wizard';
@import 'custom/plugins/responsive-table';
@import 'custom/plugins/datatable';
@import 'custom/plugins/table-editable';
@import 'custom/plugins/apexcharts';
@import 'custom/plugins/flot';
@import 'custom/plugins/sparkline-chart';
@import 'custom/plugins/google-map';
@import 'custom/plugins/vector-maps';
@import 'custom/plugins/leaflet-maps';

// Pages
@import 'custom/pages/authentication';
@import 'custom/pages/ecommerce';
@import 'custom/pages/email';
@import 'custom/pages/chat';
@import 'custom/pages/coming-soon';
@import 'custom/pages/timeline';
@import 'custom/pages/extras-pages';
  
@import './breakpoint.scss';

@media (min-width: 700px) {
	.main-content {
	  margin-left: 3px !important;
	}
}

  @media (max-width: 600px) {
    .page-content {
      padding-bottom: 20px !important;
    }
  }

  .react-loading-skeleton{
    border-radius: 20px;
  }

  svg {
    width: inherit;
    height: inherit;
  }

  .downloadQRefSection{
    position: absolute;
    left: -4000px;
  }

  .tableLayout tbody > *{
   vertical-align: middle;
  }
