$font-family-Heebo: Heebo, sans-sarif;

button {
  min-height: 45px;
  text-align: center;
  font-size: 16px;
  display: flex;
  align-items: center;

  .buttonAsyncLoader .loader {
    justify-content: center !important;
  }
}
.btn-outline-secondary {
  border-color: #055aee !important;
  color: #055aee !important;
}
.btn-primary {
  background-color: #055aee !important;
  border-color: #055aee !important;
  @media (max-width: 767px) {
    margin-bottom: 14px;
  }
  .btnText {
    color: white;
  }

  &:disabled,
  &.disabled {
    background-color: #697378 !important;
    border-color: #697378 !important;
    cursor: not-allowed;
    color: white !important;
  }

  &.buttonAsyncLoader .loader {
    font-size: 16px;
    column-gap: 10px !important;
    background-color: #697378 !important;
    color: white;
    justify-content: center !important;
  }
}

.outline-primary {
  border-color: #055aee !important;
  color: #055aee !important;

  &:disabled,
  &.disabled {
    color: #8a99a1 !important;
    border-color: #8a99a1 !important;
    cursor: not-allowed;
  }
}

.btnText {
  display: flex;
  justify-content: center;
  align-items: center;
  & &__text {
    font-family: $font-family-Heebo;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.005em;
    &.leftIcon {
      margin-left: 12px;
    }
    &.rightIcon {
      margin-right: 12px;
    }
  }
}
