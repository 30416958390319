$border-input: #8a99a1;
$primary: #055aee;
$label-color: #153243;

$font-family-Heebo: Heebo, sans-sarif;

.text-input {
  font-family: $font-family-Heebo;
  & &__label {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: $label-color;
    background-color: transparent;
    margin-bottom: 1.5px;
    outline: none !important;
  }

  & &__description {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.004em;
    color: #8a99a1;
    margin-bottom: 5px;
  }
  & &__feedback {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    letter-spacing: 0.004em;
    color: #153243;
    margin-top: 5px;
    &.is-invalid {
      color: #ff4d64;
      margin-top: 5px;
    }
  }
}

.text-input__label {
  &.is-invalid {
    color: red;
  }

  &:not(.is-invalid) .requiredTag {
    font-size: 12px;
    margin-left: 5px;
    font-weight: 100;
    color: red;
  }

  &:is(.is-invalid) .requiredTag {
    font-size: 12px;
    margin-left: 5px;
    font-weight: 100;
    color: rgb(61, 3, 3);
  }
}

.textInput_holder {
  position: relative;
  height: 50px;
  border: 1px solid $border-input;

  border-radius: 5px;

  & input {
    font-family: $font-family-Heebo;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: $label-color;
    background-image: none !important;
    padding-right: 0.75rem !important;
    border: none !important;
    height: 100% !important;
  }

  & select {
    font-family: $font-family-Heebo;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: $label-color;
    padding-right: 0.75rem !important;
    border: none !important;
    height: 100% !important;
  }

  &.is-invalid {
    border-color: #ff4d64;
    box-shadow: none;
  }

  &.active {
    border-color: $primary;
  }

  &.disabled {
    background-color: #f5f6f8;
    border-color: #d0d6d9 !important;
  }

  & .rightIcon {
    position: absolute;
    right: 5px;
    top: 25%;
  }
}
