.navbar-header {
  background: #153243 !important;
  color: white;
  padding: 0px 10px;
  img {
    margin-top: 10px;
  }

  .lynkBrandLogo {
    display: block;
    margin-right: 1em;
  }

  .page-topbar {
    margin: 0px;
  }

  .navbar-header .navbar-brand-box {
    position: static;
    display: flex;
    background-color: rgba(21, 50, 67, 1);
  }
  .nav-item {
    position: relative;
    color: white;
    font-family: Heebo;
    line-height: 15px;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 1px;
    margin-left: 20px;
    padding: 0 12px;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    &.active {
      background-color: rgba(44, 71, 86, 1);
      border: solid 0.15em rgba(20, 218, 114, 1);
    }
  }
  .logo {
    height: 32px;
    margin-top: 0;
    margin-left: 8px;
    &.lynk {
      height: 22px;
    }
  }

  .menuLeftBody {
    display: flex;
    width: 85%;
    @media (max-width: 600px) {
      gap: 11px;
    }
  }

  .menuToggle {
    height: 100%;
    display: flex;
    align-items: center;
    @media (min-width: 601px) {
      display: none;
    }
  }

  .menuToggle {
    @media (max-width: 600px) {
      display: flex;
      align-items: center;
      gap: 5px;
    }
    .logoContainer {
      display: none;
      @media (max-width: 600px) {
        display: flex;
      }
    }
  }

  .logoContainer {
    display: flex;
    align-items: center;
    width: 157px;
    @media (max-width: 600px) {
      display: none;
    }
  }

  .walletToggle {
    margin-left: 25px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 600px) {
      display: none;
    }

    & .walletOption {
      cursor: pointer;
      padding: 6px 12px;
      display: flex;
      align-items: center;
      gap: 9px;

      @media (max-width: 636px) {
        gap: 5px;
      }

      & svg {
        width: 20px;
      }
      &:hover:not(.active) {
        border: 1px solid #28f78977;
        border-radius: 17.1429px;
        margin-left: 5px;
      }

      &.active {
        background: #2c4756;
        border: 1px solid #14da72;
        border-radius: 17.1429px;
      }
    }
  }
  .account-menu {
    @media (max-width: 600px) {
      display: none !important;
    }
  }
  .account-mobile {
    display: none;
    @media (max-width: 600px) {
      display: flex;
      align-items: center;
    }
  }

  @media only screen and (max-width: 600px) and (orientation: portrait) {
    .page-topbar {
      height: 50px;
    }

    .nav-item {
      display: none;
    }

    .navbar-header {
      height: 50px;
      justify-content: space-between;
      & .menuContainer {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .navbar-brand-box,
        .profileContainer {
          display: flex !important;
          align-items: center;
        }

        .profileContainer .btn {
          height: 100%;
        }
      }
    }
  }
}
