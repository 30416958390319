.settingsPage {
  margin: 0px 35px;
  padding: 10;
  @media (max-width: 600px) {
    margin: 0;
    padding: 0;
  }

  .notificationHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .saveButton {
    padding-left: 5em;
    padding-right: 5em;
    margin-left: 2em;

    @media (max-width: 600px) {
      padding-left: 2em;
      padding-right: 2em;
      margin-left: 0;
    }
  }

  .notificationsBody {
    background: #fff;
    padding: 20px;
  }
  .preferenceTitle {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Heebo';
    color: #153243;
    line-height: 21px;
    letter-spacing: 0.5%;
  }

  .currencyFieldReset {
    margin-bottom: -1rem;
  }

  .balanceThresholdAmount {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 1rem;

    // for mobile screen sizes set the flex direction to column
    @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
    }

    .instruction {
      font-weight: 500;
    }
  }
}
