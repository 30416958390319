.filterOptionGroup {
  background-color: white;
  @media (max-width: 600px) {
    &__item {
      &--btn {
        width: 100%;
        border-color: #455b69;
        color: #455b69;

        & .btnText__text.rightIcon {
          width: 301px;
        }
      }
    }
  }

  & &__item {
    padding: 10px;
    border: none;
    background: none !important;
    color: #153243;
    font-size: 16px;
    font-family: Heebo;
    box-shadow: none !important;

    &--btn {
      background: none !important;
      color: #153243;
      border: 1px solid #153243 !important;
      margin-left: 0;

      & .btnText {
        color: #153243;
        // &:hover {
        //   color: white;
        // }
      }

      svg {
        margin-right: 0;
        margin-left: 12px;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    // &--btn:hover {
    //   background-color: #055aee !important;
    //   color: white !important;
    //   box-shadow: none !important;
    //   border: 1px solid #055aee !important;
    // }

    // &--btn:active {
    //   border: 1.5px solid #055aee !important;
    //   & .btnText {
    //     color: #17499f;
    //   }
    // }

    // &--icon:hover,
    // &--icon:focus,
    // &--icon:active {
    //   background: none !important;
    //   box-shadow: none !important;
    // }
  }
}

.lynk-modal .filter-accordian {
  border-bottom: 1px solid #b9c2c7;
}

.accordion-header > button {
  width: 94%;
}

@media (max-width: 426px) {
  .selectedFilterBtn {
    margin-left: 0px;
  }
}
.rangeDetailText {
  margin-bottom: 12px;
}
