.viewBatchPayments {
  width: auto;
  position: relative;
  .notificationIcon {
    position: absolute;
    width: 14px;
    height: 14px;
    background: red;
    z-index: 2;
    border-radius: 100%;
    top: 0;
    right: 0;
  }
}

.activeWidget {
  border: 3px solid #0559ee;
}
