.batch-button {
  border: solid 1px;
  min-height: 20px !important;
}

.batch-button:hover {
  border: solid 1px !important;
}

@media (max-width: 600px) {
  .batch-button {
    min-height: 10px !important;
    padding: 5px 7px !important;
  }
}
