$font-family-Heebo: Heebo, sans-sarif;
$label-color: #153243;

.transferAmount {
  &__inputContainer {
    position: relative;
    border-radius: 4px;
    border: 1px solid #8a99a1;
    padding-left: 1.5rem;
  }

  &__inputContainer::after {
    position: absolute;
    left: 12px;
    top: 8px;
    content: 'J$';
    color: #73848e;
  }

  font-family: $font-family-Heebo;
  &__input {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: $label-color;
    border: none !important;
    outline: none;
    width: 100%;
  }

  &__input::placeholder {
    font-family: $font-family-Heebo;
    font-size: 14px;
  }

  &__amount {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.004em;
    color: $label-color;
    margin-bottom: 5px;
  }
}

.payeeDetails {
  &__container {
    border-radius: 4px;
    border: 1px solid #d0d6d9;
    background: #e8ebec;
    padding: 8px 12px;
    margin-bottom: 15px;

    color: #153243;
    font-family: $font-family-Heebo;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.08px;
  }

  &__subContainer {
    color: #5b707b;
    font-family: $font-family-Heebo;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.07px;
  }

  &__name {
    display: flex;
    flex-direction: row;
  }
}
