.filterOptionGroup {
  background-color: white;
  @media (max-width: 600px) {
    &__item {
      &--btn {
        width: 100%;
        border-color: #455b69;
        color: #455b69;

        & .btnText__text.rightIcon {
          width: 301px;
        }
      }
    }
  }
  display: flex;

  @media (max-width: 601px) {
    flex-direction: column;
    .filterListing {
      width: 100%;
      margin-top: 10px;
    }
  }
}
