.text-input {
  & input {
    height: 45px;
  }
}

.text-input displayNameInput {
  margin-bottom: 0px;
}

.react-switch-label {
  width: 60px;
  height: 30px;
  .react-switch-button {
    width: 26px;
    height: 26px;
  }

  .react-switch-label .react-switch-button {
    left: 3px;
  }
  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }
}

.userStatusDetails {
  display: flex;
  flex-direction: column;
  & .main {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.005em;
  }

  & .description {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.004em;
  }
}

.displayNameInput {
  margin-bottom: 0px !important;
}

.tooltip {
  z-index: 1151 !important;
  text-align: left;
  left: 0;
}
