.paymentRequestForm {
  & &__noteInput {
    textarea {
      height: 116px;
      resize: none;
    }

    ::placeholder {
      color: #a1adb4;
    }
  }
}
