.authMiddleware {
  position: relative;
  .general-toast {
    @media (max-width: 600px) {
      min-width: 300px;
      right: 2px;
      top: 69px;
      z-index: 20;
    }
    min-width: 480px;
    position: absolute;
    right: 20px;
    top: 149px;
    z-index: 20;

    .toast-text__container {
      margin-right: 4px;
    }
  }
}
