$primary: #055aee;

.react-datepicker-popper[data-placement^='bottom'] {
  margin-left: 10px;
  padding-top: 0px;

  @media (max-width: 600px) {
    position: absolute;
    inset: -95px auto auto 0px !important;
    margin-left: 0px;
    &.mode-start {
      transform: translate3d(0px, 174.5px, 0px) !important;
    }

    &.mode-end {
      transform: translate3d(0px, 267.5px, 0px) !important;
    }
  }
}

.react-datepicker {
  display: flex;
  background-color: #ededed;

  .react-datepicker__triangle {
    display: none;
  }

  // .react-datepicker__week{
  //   background: #E6EFFD;
  //   border-radius: 15px;
  // }

  .react-datepicker__month-container {
    background: white;
    padding: 2px 1px;

    &:not(:last-child) {
      margin-right: 3px;
    }

    @media only screen and (max-width: 500px) {
      /* For mobile phones: */
      width: 315px;
    }
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    padding: 0;
    top: 5px;
  }

  & &__header {
    background-color: transparent;
    border: none;
  }

  .react-datepicker__current-month {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #00000099;
  }
  .react-datepicker__navigation {
    top: 14px;
  }

  &
    &__day--in-range:not(.react-datepicker__day--range-start):not(.react-datepicker__day--range-end) {
    background: #e6effd;
    color: black;
  }

  & &__day--range-start,
  & &__day--range-end {
    background: #0559ee;
  }

  & &__day--outside-month {
    color: lightgray;
  }
  .react-datepicker__day:hover,
  .react-datepicker__day--keyboard-selected,
  & &__day--in-range,
  & &__day--selected,
  & &__day--in-selecting-range,
  & &__day--selecting-range-start,
  & &__day--selecting-range-end {
    border-radius: 100%;
  }

  & &__day-names * {
    color: #00000099;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
}

.datePickerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & &__icon {
    cursor: pointer;
  }

  & &__inner {
    position: relative;

    &--invalid input {
      border-color: red;
    }

    &--invalid svg {
      color: red;
    }

    & input {
      width: 100% !important;
      padding: 12px;
      border-radius: 4px;
      border: 1px solid #8a99a1;
      &:active {
        border-color: $primary;
        box-shadow: none !important;
      }
      &:active + .text-input__label {
        color: $primary !important;
      }
      &:focus {
        border-color: $primary;
        box-shadow: none !important;
      }
      &:focus + .text-input__label {
        color: $primary !important;
      }
    }

    & svg {
      position: absolute;
      top: 10px;
      right: 14px;
    }
  }
}
