.tableEmptyStatusView {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  margin: 30px 20px;

  &__header {
    font-family: 'Armin Grotesk';
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: #0559ee;
    margin-bottom: 12px;
  }

  &__subheader {
    font-family: 'Heebo';
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.005em;

    color: #153243;
  }

  &__icon {
    width: 216px;
  }
}
