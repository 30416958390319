.singlePageDashboard {
  position: relative;
  .dashboardCarousel {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: flex-start;
    margin-bottom: 20px;
    column-gap: 27px;
    overflow-y: scroll;

    .carousel {
      margin-bottom: 30px !important;
      flex-wrap: nowrap;
      white-space: nowrap;
      @media (min-width: 600px) {
      }
    }

    .carousel__item {
      padding: 10px !important;
      @media (min-width: 790px) {
        padding: 0px !important;
      }
    }

    & .balance-widget {
      height: 300px;
    }
  }

  @media (max-width: 600px) {
    .dashboardCarousel {
      display: none;
    }
  }

  @media (min-width: 601px) {
    .dashboardCarousel--mini {
      display: none;
    }
  }
}
