/**
 * @section Breakpoints
 * These values will not show up in content, but can be queried by JavaScript to know which breakpoint is active. Add or remove as many breakpoints as you like.
 */

 body:before {
	content: "smartphone";
	display: none; /* Prevent from displaying. */
  }

  @media (max-width:601px) {
	body:before {
	  content: "smartphone";
	}
  }

  @media (min-width: 601px) {
	body:before {
	  content: "tablet";
	}
  }
  @media (min-width: 1100px) {
	body:before {
	  content: "desktop";
	}
  }
