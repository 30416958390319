.table__section--container tbody {
  background-color: #f5f6f8 !important;
}
.unread {
  background-color: #e6effd !important;
}
.notificationsCardContainer {
  background: #ffffff;
  border-radius: 16px;
  padding: 12px 20px;
  cursor: pointer;
  margin-bottom: 15px;
  margin-left: 20px;
  margin-right: 20px;
  @media (max-width: 600px) {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .title {
    padding: 2px 8px;
    font-size: 14px;
    line-height: 21px;
    color: #153243;
    font-family: 'Heebo';
    font-weight: 500;
    // border-radius: 40%;
  }

  .transAmt {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.005em;

    /* darkBlue/Dark100 */

    color: #153243;
  }

  .username {
    font-family: 'Armin Grotesk';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: 0.001em;
    color: #2c4756;
    word-wrap: break-word;
    overflow-wrap: anywhere;
  }

  .transTimestamp {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    padding: 0 8px;
    text-align: left;
    letter-spacing: 0.004em;

    /* darkBlue/Dark400 */

    color: #5b707b;
  }

  .moreDetailsNotification {
    color: #5b707b;
    cursor: pointer;
    font-family: 'Heebo';
    font-style: normal;
  }

  .cardTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11.5px;
  }

  .cardDataContainer {
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
  }
  .unread {
    border-left: solid 3px #0559ee;
  }
}
