.merchantTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  & &__name {
    font-weight: bold;
    font-size: 25px;
  }

  & &__id {
    color: #8a99a1;
    font-weight: normal;
    font-size: 15px;
  }

  @media (min-width: 768px) {
    & {
      align-items: center;
      height: auto;
      // margin: 24px 52px;
    }
  }
}
