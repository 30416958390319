$primary: #055aee;

.selectorItem {
  border-radius: 5px;
  padding: 5px;
  & > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 5px;
  }
}

.titleLoading {
  margin-bottom: 10px;
}

// .optionListing{
//   width: 100%;
//   border: 1px solid #8A99A1;
//   /* width: 428px; */
//   height: 58px;
//   background: #FFFFFF;
//   border-radius: 4px;
//   padding: 12px 15px;
//   display: flex;
//   flex-direction: row;
//   gap: 9px;
//   padding: 12px 15px;
//   &Icon{
//     border: 1px solid #E8EBEC;
//     width: 35px;
//     height: 35px;
//     border-radius: 5px;
//   }
// }

.selectorTitle {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.005em;

  margin-bottom: 7.5px;
  /* darkBlue/Dark100 */

  color: #153243;

  &.active {
    color: #0559ee;
    &:hover {
      color: #0559ee;
    }
  }
}

.selectorLoader {
  display: flex;
  /* width: 100%; */
  flex-direction: row;
  align-items: center;
  gap: 12px;

  & .iconLoading {
    border-radius: 2px;
  }

  & .detailsLoadingGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 10px;
    & span {
      height: 16px;
    }
  }
}

.boxSelector.disabled {
  border: none;
  .selectorBase {
    // background-color: #d5dee0;
    background-color: #f1f1f1;
    color: white;
    border-color: none !important;
  }
}

button.selectorBase:hover {
  background: #ffffff !important;
  color: #8a99a1 !important;
}

.selectorBase {
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  border: none;
  border-radius: 6px;
  &:active {
    border-color: $primary;
    box-shadow: none !important;
  }
  .dropdown-menu.show {
    top: 96% !important;
    border: 1px solid #8a99a1 !important;
  }

  & .selectorBaseInner {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 9px;
    &--icon {
      padding: 5px;
      border: 1px solid rgb(232, 235, 236);
      border-radius: 5px;
      color: #2c4756 !important;

      text-align: center;
      & svg {
        width: 25px;
      }
    }

    &--text {
      color: #74788d;
      font-size: 16px;
    }
  }
}

.boxSelector {
  border: 1px solid #8a99a1;
  height: 58px;
  border-radius: 6px;
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    background: #d5dee0;
  }
  &.active:not(.disabled) {
    border-color: #0559ee;
  }
  .dropdown-item {
    padding: 5px;
    border-radius: 5px;
  }
  &:active {
    border-color: $primary;
    box-shadow: none !important;
  }
  &:focus {
    border-color: $primary;
    box-shadow: none !important;
  }
  &:hover {
    border-color: $primary;
    box-shadow: none !important;
  }
}

.selectorBaseMenu {
  width: 100%;
  padding: 15px;
  border: 1px solid #8a99a1;
  &:hover {
    border-color: $primary;
    box-shadow: none !important;
  }
  &:hover + .selectorTitle {
    color: $primary;
  }
  & &--Item {
    border-radius: 5px;
    padding: 5px;

    .selectorBaseInner {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 9px;
      &--icon {
        // padding: 5px;
        border: 1px solid rgb(232, 235, 236);
        border-radius: 5px;
        width: 35px;
        height: 35px;
        text-align: center;
        & svg {
          width: 25px;
        }
      }

      &--text {
        color: #495057;
        font-size: 16px;
      }
    }
  }
}
.inactive {
  color: #8a99a1 !important;
}
