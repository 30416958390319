.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
  margin-top: -5px;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &.--disabled {
    background-color: #d0d6d9 !important;
    cursor: not-allowed;
  }

  &:not(.--checked) {
    border: solid 2px #2c4756;

    .react-switch-button {
      width: 24px !important;
      height: 24px !important;

      background-color: #2c4756;
      transform: translate(0%, -50%);
      top: 50%;
    }

    &.--disabled {
      border: none;

      .react-switch-button {
        background-color: white;
        left: 3px;
      }
    }
  }

  border-radius: 100px;
  position: relative;
  width: 43px;
  height: 22px;
}

.react-switch-label .react-switch-button {
  position: absolute;
  top: 2px;
  left: 2px;

  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 40px;
}
