$label-active: #153243;
$enabled-border-color: #8a99a1;
$error: #ff4d64;
$active-color: #055aee;

$font-family-Heebo: Heebo, sans-sarif;

.refund-details-form {
  .text-input .text-input__feedback.is-invalid {
    text-align: right !important;
  }

  & &__container {
    &__label {
      font-family: $font-family-Heebo;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      // display: flex;
      align-items: center;
      letter-spacing: 0.005em;
      color: $label-active;

      &.invalid {
        border-color: $error !important;
        color: $error !important;
      }

      &.active {
        color: $active-color !important;
      }
    }

    .text-input__input {
      font-family: $font-family-Heebo;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #5b707b;
      margin-bottom: 8px;
      border: 1px solid $enabled-border-color;

      &:focus {
        box-shadow: none;
      }

      &.invalid {
        border-color: $error !important;
        color: $error !important;
      }

      &.is-invalid {
        border-color: $error !important;
      }

      &.disabled {
        border-color: #d0d6d9 !important;
      }

      &.active {
        border-color: $active-color !important;
      }
    }
  }

  & &__actions {
    padding-top: 16px;
    margin-top: 32px;

    &__cancel-button {
      border: 1px solid #8a99a1;
      margin-top: 16px;
      font-family: $font-family-Heebo;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.005em;
      color: #153243;
    }

    &__primary-button {
      font-family: $font-family-Heebo;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.005em;
      color: #ffffff;
    }
  }
}
