.cbdcQRCodeDownloadContainer {
  margin: 0 auto;
  width: 515px;
  height: 700px;
  background-color: white;
  // background: url(../../../assets/images/scan_to_pay.png) no-repeat;
  background-size: cover;
  padding-top: 45px;
  position: relative;

  header {
    margin-bottom: 45px;
    height: 90px;
    padding-left: 30px;
    padding-right: 30px;
    & > div {
      position: relative;
      width: 100%;

      .lynkLogo {
        border: 1px solid black;
        color: rgb(78, 73, 73);
        padding: 24px;
        border-radius: 100%;
        width: 130px;
        height: 130px;
        background-color: white;
        z-index: 3;
        top: -20px;
        left: 20px;
        position: absolute;
        & svg {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .qrSection {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(45deg);
    & > div {
      border: 3px solid black;
      background: #fff;
      height: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: 250px;
      border-radius: 52px;
      position: relative;

      & canvas {
        width: 174px;
        height: 174px;
      }
    }
  }

  .merchantDetailGroup {
    line-height: 1.5em;
    background-color: #cb0e0e;
    height: 90px;
    /* width: 100%; */
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    padding: 0px 10px 0px 130px;
    display: flex;
    align-items: center;
    color: white;
    width: 408px;
    position: absolute;
    right: 22px;
    border-radius: 25px;

    .merchantTitle {
      align-items: flex-start !important;
    }
    & .merchantTitle__name {
      font-family: 'Armin Grotesk';
      font-size: 88px;
      font-weight: 800;
      // line-height: 125px;
      letter-spacing: 0.04em;
      text-align: left;
      font-size: 22px;
    }
    & .merchantTitle__cashier {
      font-size: 18px;
      font-family: 'Armin Grotesk';
      font-weight: 300;
      letter-spacing: 0.04em;
      text-align: left;
    }
    & .merchantTitle__id {
      font-size: 15px;
      font-family: 'Armin Grotesk';
      font-weight: 250;
      letter-spacing: 0.04em;
      text-align: left;
      color: white;
    }
  }

  .details {
    color: white;
    display: flex;
    justify-content: space-between;
  }

  .footerDetails {
    margin-top: 58px;
    padding: 0px 55px;
    padding-top: 30px;
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 80px;

    & .scanToPay {
      width: 220px;
    }

    & .lynkLogo {
      width: 223px;
      margin-top: 3px;
    }

    .downloadGrp {
      color: white;
      width: 145px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 50px;
    }

    & .lynkLogo {
      width: 250px;
      height: 101px;
    }
  }
}
