//
// Google font - IBM Plex Sans
//

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: 'Heebo';
  src: url(../../../../assets/fonts/Heebo-VariableFont_wght.ttf);
}

@font-face {
  font-family: 'Armingrotesk';
  src: url(../../../../assets/fonts/armingrotesk-regular.otf);
}

@font-face {
  font-family: 'Armin Grotesk';
  src: url(../../../../assets/fonts/armingrotesk-regular.otf);
}

