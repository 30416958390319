.orders-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.moreDetailsIcon {
  color: #5b707b;
  cursor: pointer;
}
