.notFoundTemplate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // width: 100%;
  margin: 10px 30px;
  @media (min-width: 500px) {
    & {
      margin: 0 auto;
    }
  }

  &__pageDetails {
    text-align: center;
    margin: 30px 0 0 0;
    & .computerIcon {
      padding: 30px;
      width: 100%;
    }
    & h3 {
      margin-top: 20px;
      margin-bottom: 20px;
      font-family: 'Armin Grotesk';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      color: #153243;
      line-height: 40px;
      display: flex;
      align-items: center;
      text-align: center;
      flex-direction: column;
      letter-spacing: -0.02em;

      @media (min-width: 769px) {
        flex-direction: row;
        font-size: 42px;
        line-height: 70px;
        & span {
          margin-left: 9px;
        }
      }
    }

    & &--subtext {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      padding-left: 30px;
      margin: 0px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.005em;
      flex-direction: column;

      color: #153243;
      @media (min-width: 992px) {
        font-weight: 400;
        font-size: 20px;
        margin: 72px;
        & .computerIcon {
          padding: 30px;
        }
      }
    }
  }
}
.goHomeButton {
  width: 80%;
  margin-top: 3em;
}
