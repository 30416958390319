.table-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 600px) and (orientation: portrait) {
    & {
      // display: flex;
      flex-direction: row;
    }
  }

  flex-direction: row;
  & .pagerDetails {
    display: flex;
    justify-content: flex-start;
  }
  @media only screen and (max-width: 600px) {
    & {
      gap: 10px;
      flex-direction: column-reverse;
      justify-content: center;
    }

    & .pagerDetails {
      justify-content: center !important;
    }
  }

  & .pagerItem {
    display: flex;
    justify-content: center;
  }

  @media (min-width: 601px) {
    & .pagerItem {
      justify-content: flex-end;
    }
  }

  .selectedPage,
  .pageNumItems {
    display: flex;
  }

  & &__text {
    font-family: Heebo;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;

    & span {
      margin-right: 5px;
    }
  }

  & &__pagination {
    font-family: Heebo;
    color: #b9c2c7;
    cursor: pointer;
    margin-bottom: 0;
    a {
      border-color: #b9c2c7;
      &.disabled {
        cursor: not-allowed;
      }

      &.not-disabled {
        cursor: pointer;
      }

      &.firstPageBtn {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &.lastPageBtn {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}
