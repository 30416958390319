//
// _dropdown.scss
//

.dropdown-menu {
  box-shadow: $box-shadow;
  margin: 0;
}

.dropdown-menu {
  left: 0;
  margin-top: 0.125rem;
}
.dropdown-menu-end {
  left: auto;
  right: 0;
}

.dropdown-menu[data-popper-placement^='right'],
.dropdown-menu[data-popper-placement^='top'],
.dropdown-menu[data-popper-placement^='left'] {
  top: auto !important;
  animation: none !important;
}

@keyframes DropDownSlide {
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}

@media (min-width: 600px) {
  .dropdown-menu-lg {
    width: 320px;
  }

  .dropdown-menu-md {
    width: 240px;
  }
}

.dropdown-divider {
  border-top-color: $border-color;
}

// Dropdown size

.dropdown-mega-menu-xl {
  width: 40rem;
}

.dropdown-mega-menu-lg {
  width: 26rem;
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    right: 0 !important;
    left: auto !important;
  }
}
