.iconWidget {
  height: 131px;
  width: 130px;
  border-radius: 12px;
  padding: 0px;
  text-align: center;

  &:not(.disabled) {
    cursor: pointer;
  }

  &.disabled {
    background: #e8ebec;
    cursor: not-allowed;
    box-shadow: none;

    & .card-body > * {
      color: #8a99a1;
    }
  }

  & .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  & &__text {
    font-size: 16px;
    white-space: initial;
    color: #153243;
  }
  & &__icon {
    margin-bottom: 10px;
    &:not(.disabled) {
      color: #0559ee;
    }

    &.disabled {
      color: #8a99a1;
    }
  }
}
