//
// backgrounds.scss
//

@each $color, $value in $theme-colors {
  .bg-soft-#{$color} {
    background-color: rgba(($value), 0.25) !important;
  }
}

@each $color, $value in $theme-colors {
  .bg-gradient-#{$color} {
    background: linear-gradient(
      to bottom,
      ($value),
      rgba(($value), 0.5)
    ) !important;
  }
}
