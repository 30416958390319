.accessDenied--wrapper {
  height: 70vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    & {
      height: 90vh;
    }
  }
}

.accessDenied--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    & {
      padding: 0 4rem;
    }
  }
}

.accessDenied--headerText {
  font-size: 51px;
  font-family: 'Armin Grotesk';

  margin-top: 2rem;

  @media (max-width: 600px) {
    & {
      font-size: 30px;
    }
  }
}

.accessDenied--text {
  color: #153243;
  font-family: 'Armin Grotesk';
  letter-spacing: 0.032px;

  margin-top: 1rem;

  @media (max-width: 600px) {
    & {
      text-align: center;
    }
  }
}

.computer-icon {
  @media (max-width: 600px) {
    & {
      width: 250px;
    }
  }
}
