.breadcrumbs-container {
  margin: 20px 0px;
  width: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 12px 16px;
  border-radius: 4px;
  background: #e8f1f2;
}

.breadcrumb-item {
  font-family: 'Armin Grotesk';
  color: #778b95 !important;
  font-size: 17px;
}

a.breadcrumb-item {
  text-decoration: underline !important;
}
._active {
  color: #5b707b !important;
}
.breadcrumb-item + .breadcrumb-item::before {
  font-family: 'Armin Grotesk';
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: #778b95 !important;
  font-size: 17px;
  margin: 0 10px;
  content: var(--bs-breadcrumb-divider, '>');
}

.breadcrumb {
  margin-bottom: 0;
}

// a.breadcrumb-base-item.breadcrumb-item {
//   text-decoration: underline !important;
// }
