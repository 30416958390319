.batch-Checkbox {
  width: 18px;
  height: 18px;
  margin-right: 11px;
  color: #5b707b;
}

.checkgroup-item {
  display: flex;
  color: #5b707b;
  align-content: center;
  padding-left: 0px;
  &:not(:last-child) {
    margin-bottom: 18px;
  }
}
