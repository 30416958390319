.pageTitle {
  font-size: 18px;
  margin: 33px 0 33px 20px;
  @media (max-width: 600px) {
    margin: 33px 12px;
  }
}

.settingButton {
  font-size: 16px;
  float: right;
  cursor: pointer;
  @media (min-width: 600px) {
    margin-top: 45px;
    margin-right: 16px;
  }
  span {
    margin-right: 5px;
  }
}

.notificationPage {
  padding-left: 20px;
  @media (max-width: 600px) {
    padding-left: 0;
  }
}
