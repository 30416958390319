.editDeleteGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  & svg {
    cursor: pointer;
    width: 20px;
    height: 20px;

    &:hover {
      width: 30px;
      height: 30px;
    }
  }

  &:not(.mini) {
    width: 100%;
    justify-content: space-evenly;
    gap: 12px;
  }
}
