.topnav--open {
  width: 305px;
  max-height: 100%;
  height: 100%;
  background-color: rgba(21, 50, 67, 1);
  font-family: 'Armin Grotesk';
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.05em;
  font-size: 16px;
  line-height: 24px;
  color: white;
}

.menuOverlay {
  background: #5b707b;
  opacity: 0.3;
}

.menuContainer {
  padding: 0 16px;
  z-index: 4;
  overflow-y: auto;
  height: 90vh;
}

.quickAccess {
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  border-top: solid 1px rgba(91, 112, 123, 1);
  padding-top: 12px;
}

.cash-inIcon,
.cash-outIcon {
  height: 20px;
  width: 20px;
}

.navbar-nav {
  margin-left: 10px;
  padding: 0px;

  .nav-item-sidebar {
    left: 0px;
    top: 0px;
    border-radius: 0px;
    padding: 0px 10px;
    cursor: pointer;
    .nav-link {
      display: flex;
      align-items: center;
      font-family: 'Heebo';
      font-style: normal;
      font-size: 14px;
      letter-spacing: 0.005em;
      padding-left: 0px;

      & > span {
        color: white;
      }

      & .linkIcon {
        margin-right: 12.24px;
        color: #0559ee;
      }
    }
  }
}

.walletToggleLeft {
  display: inline-block;

  & .walletOption {
    width: auto;
    cursor: pointer;
    padding: 6px;
    align-items: center;
    font-size: 14px;
    font-family: 'Heebo';
    font-style: normal;
    margin: 12px;
    &:first-child {
      width: 120px;
    }
    & svg {
      width: 18px;
      margin-left: 4px;
      margin-top: -2px;
    }
    &:hover:not(.active) {
      border: 1px solid #28f78977;
      border-radius: 17.1429px;
      margin-left: 5px;
    }

    &.active {
      background: #2c4756;
      border: 1px solid #14da72;
      border-radius: 17.1429px;
    }
  }
}
