.settingsCardContainer {
  background: #ffffff;
  padding-top: 8px;

  padding-bottom: 11.5px;
  margin-left: 8px;
  margin-right: 8px;
  border-bottom: solid 1px #d0d6d9;
  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 600px) {
    margin-bottom: 0;
  }
  .title {
    font-size: 14px;
    line-height: 21px;
    color: #455b69;
    font-family: 'Heebo';
    font-weight: 400;
    cursor: auto;
    // border-radius: 40%;
  }

  .moreDetailsIcon {
    color: #5b707b;
    cursor: pointer;
    font-family: 'Heebo';
    font-style: normal;
  }

  .cardTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.uniqueElement {
  border-bottom: solid 1px #d0d6d9 !important;
  margin-bottom: 20px;
}
