.lastReportModal {
  @media (min-width: 576px) {
    &.modal-dialog {
      width: 694px;
    }
  }

  .modal-header {
    border-bottom: none;
  }

  .lastReportsH4 {
    text-align: center;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    /* identical to box height, or 125% */

    letter-spacing: -0.02em;
    margin-bottom: 56px;
    /* secondary/Dark100 */

    color: #055aee;
  }
}
