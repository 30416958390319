.lynkBalanceWidget {
  border-radius: 12px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column;
  background: #1e6bf0;
  box-shadow: 0px 2px 4px rgba(40, 111, 194, 0.34);

  @media (max-width: 600px) {
    & {
      width: 100%;
      height: 98px;
      font-size: 16px;
      margin-bottom: 10px;
      padding: 0px 8px;

      & &__titleContent {
        padding: 2px 12px; //5px 5px 0px 10px;
      }
    }
  }

  @media (min-width: 601px) {
    & {
      width: 315px;
      height: 131px;
      padding: 15px 5px;
    }
  }

  & &__titleContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 15px 0px 10px;

    &--titleLeftContent {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      &_icon {
        display: flex;
        width: 20px;
        height: 20px;
        margin-top: 0;
      }

      &_text {
        font-size: 17px;
        color: white;
        font-family: 'Armingrotesk';
      }
    }
  }

  & &__balanceContent {
    width: 100%;

    padding: 10px 12px 2px 12px;
    @media (min-width: 600px) {
      margin-top: 14px;
      padding: 2px 12px;
    }

    justify-content: flex-start;
    align-items: flex-start;
  }
}
