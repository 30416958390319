.refund-button {
  border: 1px solid;
  & .btnText {
    color: #5b707b;
  }
}

.topRow {
  height: auto;
  @media (min-width: 769px) {
    & {
      height: 131px;
    }
  }

  & .lynkBalanceColumn {
    height: 100%;
    @media (min-width: 769px) {
      & {
        padding-right: 70px;
        height: 131px;
        display: flex;
        & > div:not(:first-child) {
          margin-left: 15px;
        }
      }
    }
  }

  & .qrColumn {
    margin-top: 36px;
    margin-right: 0px;
    @media (min-width: 769px) {
      & {
        height: 100%;
        margin-top: 0px;
      }
    }
  }
}

.dropdown-item {
  margin: auto;
}

.refund__toast {
  width: 194px;
}

.deposits_header {
  margin-bottom: 40px;
  & > .row {
    height: auto;
    border: 1px solid;
  }
}

.column_widget_container {
  @media (min-width: 769px) {
    width: 300px;
  }
}

.dropdown-item {
  margin: auto;
}

.refund__toast {
  width: 194px;
}

.deposits_header {
  margin-bottom: 40px;
  & > .row {
    height: auto;
    border: 1px solid;
  }
}

.column_widget_container {
  @media (min-width: 769px) {
    width: 300px;
  }
}

.bp-review-batch {
  width: 150px !important;
}
