.launch-button {
  border: none;
}
.calculatePorcentage {
  text-align: center;
}
.percentage {
  background-color: #e8ebec;
  height: 7px;
}
