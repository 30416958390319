.table__section {
  background-color: white;
  padding: 10px;
}

.table__section,
.card__section {
  .buttonAsyncLoader {
    height: 200px;
    display: flex;
    justify-content: center;
  }
}

.table__section--container {
  margin-top: 5px;
  @media only screen and (max-width: 600px) and (orientation: portrait) {
    & {
      display: none;
    }
  }

  @media only screen and (max-width: 600px) and (orientation: portrait) {
    & {
      display: none;
    }
  }

  & &__empty-message {
    padding-top: 48px;
    padding-bottom: 48px;
    font-family: Heebo;
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.005em;
    text-align: center;
  }

  .tableColumn {
    display: inline-flex;
    align-items: center;
  }

  thead {
    background-color: #e8ebec;
    font-family: Heebo;

    th {
      min-width: 120px !important;
      max-width: 180px !important;
    }
  }

  tbody {
    background-color: white;
    font-family: Heebo;
    font-size: 14px;
    font-weight: 400;
  }
}

.sortIcon {
  display: inline;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.card__sectionContainer {
  display: none;

  @media only screen and (max-width: 600px) and (orientation: portrait) {
    & {
      margin-top: 16px;
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}

.btnText .btnText__text.rightIcon {
  margin-right: 4px;
}

.From,
.To {
  word-break: break-word;
  overflow-wrap: break-word;
  width: 50px;
}
