.dropdown-item {
  display: flex !important;
  flex-direction: row;
  & > svg {
    margin-right: 11px;
    color: #5b707b;
  }

  & > div {
    color: #153243;
  }
  cursor: pointer;
}

.header-item {
  color: white;
}

.profile-label {
  &__active {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    color: #fff;
  }
}
