.accountLimitsWidget {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column;
  border-radius: 20px;

  @media (max-width: 600px) {
    & {
      width: 100%;
      height: 100px;
      font-size: 16px;
      margin-bottom: 10px;
      padding: 10px 16px;
    }
  }

  @media (min-width: 601px) {
    & {
      width: 340px;
      height: 131px;
    }
  }

  & &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 600px) {
      & {
        justify-content: start;
      }
    }
  }

  & &__header {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &--title {
      font-size: 17px;
      color: black;
      font-family: 'Armingrotesk';
    }
  }

  & &__dropdownContainer {
    height: 40px;
    width: 100px;
    position: relative;
  }

  & &__dropdown {
    position: absolute;
    & button {
      position: absolute;
      background: none;
      outline: none;
      border: none;
      color: #0559ee;

      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      font-family: 'Heebo';
      line-height: 15px;

      padding: 0;
      height: 30px;

      display: flex;

      &:hover {
        background: none;
        color: #0559ee;
      }
    }

    &--menu {
      padding: 8px 12px;
      border-radius: 0px 0px 16px 16px;
    }

    &--menuItem {
      color: #455b69;
      font-family: 'Work Sans', sans-serif;
      font-size: 12px;
      font-weight: 400;
      white-space: nowrap;
      line-height: 20px;
      letter-spacing: 0.138px;
      padding: 4px 0;

      cursor: pointer;

      &:hover {
        background: none;
        color: #0559ee;
      }
    }
  }

  & &__progressContainer {
    display: flex;
    flex-direction: column;

    &--details {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 8px;
    }

    &--frequency {
      color: #153243;
      font-family: 'Heebo';
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.048px;
    }

    &--usage {
      color: #153243;
      font-family: 'Heebo';
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.048px;
    }
  }

  .dropdown-menu {
    --bs-dropdown-min-width: 5rem;
  }

  .active {
    color: #0559ee;
  }
}

.card-body {
  @media (max-width: 600px) {
    & {
      padding: 0px;
    }
  }
}
