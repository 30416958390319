.status {
  &__status {
    margin-bottom: 20px;
  }

  &_spinner svg {
    width: 50px;
    height: 50px;
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// div {
//     margin: 20px;
//     width: 100px;
//     height: 100px;
//     -webkit-animation-name: spin;
//     -webkit-animation-duration: 2000ms;
//     -webkit-animation-iteration-count: infinite;
//     -webkit-animation-timing-function: linear;
//     -moz-animation-name: spin;
//     -moz-animation-duration: 2000ms;
//     -moz-animation-iteration-count: infinite;
//     -moz-animation-timing-function: linear;
//     -ms-animation-name: spin;
//     -ms-animation-duration: 2000ms;
//     -ms-animation-iteration-count: infinite;
//     -ms-animation-timing-function: linear;
//     animation-name: spin;
//     animation-duration: 2000ms;
//     animation-iteration-count: infinite;
//     animation-timing-function: linear;
//   }

//   @-ms-keyframes spin {
//     from {
//       -ms-transform: rotate(0deg);
//     }
//     to {
//       -ms-transform: rotate(360deg);
//     }
//   }

//   @-moz-keyframes spin {
//     from {
//       -moz-transform: rotate(0deg);
//     }
//     to {
//       -moz-transform: rotate(360deg);
//     }
//   }

//   @-webkit-keyframes spin {
//     from {
//       -webkit-transform: rotate(0deg);
//     }
//     to {
//       -webkit-transform: rotate(360deg);
//     }
//   }
