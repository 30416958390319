#page-topbar {
  left: 0 !important;
  background-color: rgba(21, 50, 67, 1);
}

.navbar-header {
  max-width: 100% !important;
}

.branch-name {
  margin-left: 16px;
  margin-bottom: 20px;
}
