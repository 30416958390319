.title-width {
  .modal-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    .titleDesc {
      width: 300px;
    }
  }
}
