$font-family-Heebo: Heebo, sans-sarif;
$label-color: #153243;
.text {
  position: relative;
  border-radius: 4px;
  border: 1px solid #8a99a1;
  padding: 0.47rem 0.75rem 0.47rem 2rem;
}

.text:after {
  position: absolute;
  left: 12px;
  top: 8px;
  font-size: 14px;
  content: '@';
  color: #73848e;
}

.textbox {
  font-family: $font-family-Heebo;
  &__input {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: $label-color;
    border: none !important;
    outline: none;
    width: 100%;
  }

  &__description {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.004em;
    color: $label-color;
    margin-bottom: 5px;
  }
}
