.eyeToggle {
  background: none;
  border: none;

  & &__visible,
  & &__hidden {
    color: white;
    font-size: 14px;
  }
}
