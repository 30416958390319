.lynk-modal.transactionStatusModal {
  .modal-content {
    max-width: 500px;
    min-height: 522px;
    @media (min-width: 600px) {
      min-width: 502px !important;
      width: auto !important;
    }
  }
  .transactionStatus-failure .modal-title .titleDesc {
    color: red;
  }
}

.modalBody {
  width: 430px !important;
  @media (max-width: 600px) {
    width: 260px !important;
    margin-top: 25px;
  }
}

.lynk-modal.notification {
  .modal-content {
    max-width: 500px;
    @media (min-width: 600px) {
      min-width: 502px !important;
      width: auto !important;
    }
  }
}
