$border-gray: #b9c2c7;
$primary: #055aee;
$title-color: #5b707b;
$label-active: #153243;
$title: #2c4756;
$subtitle: #73848e;
$border-image: #d0d6d9;

$font-family-Heebo: Heebo, sans-sarif;

.skeletonCurrencyInput {
  margin-top: 14px;
  background-color: #f1f1f1;
  width: 100%;
  height: auto;
  padding: 10px 10px;
}

.cash-out-modal {
  &__header {
    margin: 0px 24px;
    padding: 24px 0px 16px 0px;
    border-bottom: 1px solid $border-gray !important;

    .btn-close {
      height: 24px !important;
      min-height: 24px;
    }

    .modal-title {
      font-family: Armin Grotesk, sans-sarif;
      font-style: normal;
      font-weight: 400;
      font-size: 21px;
      line-height: 29px;
      color: $title-color;
    }
  }

  &__body {
    &__label {
      font-family: $font-family-Heebo;
      font-size: 14px;
      font-weight: bold;
      line-height: 21px;
      letter-spacing: 0.005em;
      text-align: left;
      color: $label-active;

      &.active {
        color: $primary;
      }
    }

    .limitSummary {
      font-family: $font-family-Heebo;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.005em;
      color: #73848e;
    }
  }

  &__footer {
    margin: 0px 24px;
    padding: 24px 0px 16px 0px;
    border-top: 1px solid $border-gray !important;

    .btn {
      width: 75%;
      font-family: $font-family-Heebo;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
    }
  }
}

.selectorFormatInput {
  margin-bottom: 23px;
}
.transfer-error {
  color: red;
}
