.staffTable {
  .table__section--container {
    & .table-responsive .table {
      // width: auto !important;

      & .table__empty-message {
        text-align: center !important;
      }
    }
  }

  button {
    min-height: 27px !important;
  }
}
