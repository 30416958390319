.hyperlink {
  text-decoration: underline !important;
  color: #007aff;
}

.page-content {
  height: 100%;
  &#non-auth {
    padding: 0 !important;
  }
  & #page-topbar {
    padding: 20px 30px;
    left: 0 !important;
    box-shadow: none;
    background-color: transparent;
  }
}

.notFoundTemplateBase {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
