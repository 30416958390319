.roleDetail {
  &--success .roleDetail__title {
    color: green;
  }

  &--error .roleDetail__title {
    color: red;
  }

  .roleDetail__list {
    margin-left: 5px;
  }
}
