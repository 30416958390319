$font-family-Heebo: Heebo, sans-sarif;
.noBackground {
  background-color: #f5f6f8 !important;
}
.merchantTabs {
  display: flex;
  border: none;
  margin-bottom: 18px;
  height: 70px;
  background-color: white;
  .not-display {
    display: none;
  }

  @media (max-width: 600px) {
    background-color: transparent;
    height: 50px;
    margin-bottom: 8px;
  }
  //merchantTabs

  &.oneTab {
    .merchantTabs__item {
      min-width: 210px;
    }
  }

  & &__item {
    border: none;
    height: 100%;
    & a {
      height: 100%;
      border: none;
      display: flex;
      flex-direction: column;
      color: #5b707b;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.0125em;
      flex: none;
      order: 0;
      flex-grow: 0;
      font-family: $font-family-Heebo;
      font-size: 16px;
      flex: 1;

      @media (min-width: 790px) {
        flex-direction: row;
      }

      @media (max-width: 600px) {
        line-height: 14px !important;
        padding: 6px;
        justify-content: end !important;
        // font-size: 14px;
      }
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.active {
        color: #153243;
        font-weight: bolder !important;
        //.merchantTabs .merchantTabs__item a.active
        border-bottom: 2px solid #153243;
        background-color: transparent;
        //.merchantTabs .merchantTabs__item a.active
        @media (min-width: 600px) {
          color: #0559ee;
          border-bottom: 4px solid #0559ee;
        }
      }
    }
  }
}
.tabs-pills {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;
  span {
    margin: 0 5px;
    width: 8px;
    height: 8px;
    background-color: #e8ebec;

    border-radius: 50%;
  }
  & .active {
    background-color: #0559ee;
  }
}
