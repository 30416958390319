$font-family-Heebo: Heebo, sans-sarif;

.containerRequestIdAmount {
  margin-bottom: 40px;
  & &__value {
    margin-left: 5px;
  }
}

.containerURL {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & a {
    text-decoration: underline !important;
    color: #73848e;
  }
}

.paymentRequestLink {
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  letter-spacing: 0.005em;
  & &__feedback {
    font-family: $font-family-Heebo;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #5b707b;
  }

  & &__sectionTitle {
    font-family: $font-family-Heebo;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #153243;
    margin-bottom: 8px;
    margin-top: 23px;
  }

  & &__sectionMessage {
    padding: 12px;
    border-radius: 4px;
    background: #e8ebec;
    color: #73848e;
    margin-bottom: 56px;
    font-size: 14px;
  }
}

.footerPaymentRequestModal {
  & .toastDisplay {
    width: 100%;
  }
}
