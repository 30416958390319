.batch-Checkbox {
  width: 30px;
  height: 30px;
  margin-right: 11px;
  color: #5b707b;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    background: #055aee;
    color: #fff;
    border-radius: 2px;
    width: 20px;
    height: 20px;
  }

  &.inactive {
    background-color: #fff;
    width: 20px;
    height: 20px;
  }
}

.checkGroup-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0px;
}
@media (max-width: 600px) {
  label {
    font-size: 14px;
    color: #2c4756;
  }
}
