.toggleSection-title {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-family: 'Armin Grotesk';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #0559ee;
  margin-bottom: 12px;
  @media only screen and (max-width: 500px) {
    /* For mobile phones: */
    font-size: 15px;
    // color: #5B707B;
  }
}

.toggleSection-body {
  &.hide {
    display: none;
  }

  &.visible {
    display: block;
  }
}
